:root{
  --color1: #fff;
  --color2: #fff;
  --color3: #fff;
  --color4: #fff;
}
.main-timeline{
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
}
.main-timeline:after{
  content: '';
  display: block;
  clear: both;
}
.main-timeline .timeline{
  width: 50.1%;
  padding: 20px 0 20px 100px;
  float: right;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after{
  content: '';
  background: var(--color1);
  height: 100%;
  width: 28px;
  position: absolute;
  left: -11px;
  top: 0;
}
.main-timeline .timeline:after{
  background: var(--color1);
  height: 18px;
  width: 200px;
  box-shadow: 0 0 10px -5px #000;
  transform:  translateY(-50%);
  top: 50%;
  left: -90px;
}
.main-timeline .timeline-content{
  color: #8b8b8b;
  background-color: var(--color1);
  padding: 0 0 0 80px;
  box-shadow: 0 0 20px -10px #000;
  border-radius: 10px;
  display: block;
}
.main-timeline .timeline-content:hover{
  color: #fff;
  text-decoration: none;
}
.main-timeline .timeline-icon{
  color: #000;
  background-color: var(--color1);
  font-size: 25px;
  text-align: center;
  line-height: 70px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: -100px;
  top: 50%;
  z-index: 1;
}
.main-timeline .timeline-year{
  color: #fff;
  background-color: #343a40;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 93px;
  height: 113px;
  width: 113px;
  border: 6px solid var(--color1);
  box-shadow: 0 0 10px -5px #000;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 50px;
  top: 50%;
  z-index: 1;
}
.main-timeline .inner-content{
  background-color: #171717;
  border-bottom: #fff solid;
  padding: 10px;
}
.main-timeline .title{
  color: var(--color1);
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
.main-timeline .description{
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0;
}
.main-timeline .timeline:nth-child(even){
  padding: 20px 100px 20px 0;
  float: left;
}
.main-timeline .timeline:nth-child(even):before{
  left: auto;
  right: -14.5px;
}
.main-timeline .timeline:nth-child(even):after{
  left: auto;
  right: -90px;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 0 80px 0 0; }
.main-timeline .timeline:nth-child(even) .timeline-icon{
  left: auto;
  right: -100px;
}
.main-timeline .timeline:nth-child(even) .timeline-year{
  left: auto;
  right: 50px;
}
.main-timeline .timeline:nth-child(4n+2):before,
.main-timeline .timeline:nth-child(4n+2):after{
  background: var(--color2);
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content,
.main-timeline .timeline:nth-child(4n+2) .timeline-icon{
  background-color: var(--color2);
}
.main-timeline .timeline:nth-child(4n+2) .timeline-year{ border-color: var(--color2); }
.main-timeline .timeline:nth-child(4n+2) .title{ color: var(--color2); }
.main-timeline .timeline:nth-child(4n+3):before,
.main-timeline .timeline:nth-child(4n+3):after{
  background: var(--color3);
}
.main-timeline .timeline:nth-child(4n+3) .timeline-content,
.main-timeline .timeline:nth-child(4n+3) .timeline-icon{
  background-color: var(--color3);
}
.main-timeline .timeline:nth-child(4n+3) .timeline-year{ border-color: var(--color3); }
.main-timeline .timeline:nth-child(4n+3) .title{ color: var(--color3); }
.main-timeline .timeline:nth-child(4n+4):before,
.main-timeline .timeline:nth-child(4n+4):after{
  background: var(--color4);
}
.main-timeline .timeline:nth-child(4n+4) .timeline-content,
.main-timeline .timeline:nth-child(4n+4) .timeline-icon{
  background-color: var(--color4);
}
.main-timeline .timeline:nth-child(4n+4) .timeline-year{ border-color: var(--color4); }
.main-timeline .timeline:nth-child(4n+4) .title{ color: var(--color4); }
/*@media only screen and (max-width:1200px){
  .main-timeline .timeline:before{ left: -12.5px; }
  .main-timeline .timeline:nth-child(even):before{ right: -14px; }
}
@media only screen and (max-width:990px){
  .main-timeline .timeline:before{ left: -12.5px; }
}*/
@media only screen and (max-width:1160px){
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even){
      width: 100%;
      padding: 20px 0 20px 37px;
  }
  .main-timeline .timeline:before{ left: 0; }
  .main-timeline .timeline:nth-child(even):before{
      right: auto;
      left: 0;
  }
  .main-timeline .timeline:after,
  .main-timeline .timeline:nth-child(even) .timeline:after{
      display: none;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon{
      left: 0;
      display: none;
  }
  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year{
      height: 75px;
      width: 75px;
      line-height: 60px;
      font-size: 25px;
      left: 1px;
  }
  .main-timeline .timeline-year .timeline-year-img {
    height: 65px;
    width: 65px;
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content{
      padding: 0 0 0 40px;
  }
}